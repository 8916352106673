<template>
  <el-main>
    <el-form  label-width="180px">
        <el-form-item label="预约日期：" required>
            <div class="row">
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" style="margin-right:80px;">全选</el-checkbox>
                <el-checkbox-group v-model="form.appointment_date" @change="handleCheckedTimeChange">
                    <el-checkbox v-for="item in weeks" :label="item" :key="item">{{item | weeksLabel}}</el-checkbox>
                </el-checkbox-group>
            </div>
        </el-form-item>
        <el-form-item label="预约时间间隔：" required>
           <el-select v-model="form.appointment_interval_time" size="small" placeholder="请选择" @change="form.appointment_time_frame = [[]]">
                <el-option v-for="item in interval" :key="item" :label="item | intervalLabel" :value="item"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="可预约时段：" required>
            <div v-for="(item,index) in form.appointment_time_frame" :key="index">
                <el-time-select v-model="item[0]" placeholder="选择时间" style="margin-right: 30px;" size="small"
                :picker-options="{
                    start: '00:00',
                    step: handleStep,
                    end: handleEnd
                }" >
                </el-time-select>
                <el-time-select v-model="item[1]" placeholder="选择时间" size="small"
                :picker-options="{
                    start: '00:00',
                    step: handleStep,
                    end: handleEnd,
                    minTime: item[0]
                }">
                </el-time-select>
                <i class="el-icon-close" v-if="index>0" @click="delPeriod(index)"></i>
            </div>
            <el-link :underline="false" @click="addPeriod" type="primary"><i class="el-icon-plus"></i> 添加预约时段</el-link>
        </el-form-item>
        <el-form-item label="可预约时间：" required>
            用户可预约
            <el-select v-model="form.appointment_start_time" size="small" placeholder="请选择" style="margin:0 10px;">
                <el-option v-for="item in start_time" :key="item" :label="item | startTimeLabel" :value="item"></el-option>
            </el-select>
            到
            <el-select v-model="form.appointment_end_time" size="small" placeholder="请选择" style="margin:0 10px;">
                <el-option v-for="item in end_time" :key="item" :label="item | endTimeLabel" :value="item"></el-option>
            </el-select>
            以内的任意时间到店
        </el-form-item>
    </el-form>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation'
export default {
    components:{
        Preservation
    },
  data() {
    return {
        form:{
            appointment_date:[],
            appointment_time_frame:[[]],
            appointment_start_time:'',
            appointment_end_time:'',
            appointment_interval_time:''
        },
        isIndeterminate: false,
        checkAll: false,
        weeks:['1','2','3','4','5','6','7'],
        start_time:[0,1,2,3,4,5,6,7,8,12,24,48],
        end_time:[7,15,1,2,3,6,12],
        interval:[15,30,60,120]
    };
  },
    filters:{
        weeksLabel(val){
            switch (val){
                case '1':
                    return '星期一'
                    break
                case '2':
                    return '星期二'
                    break
                case '3':
                    return '星期三'
                    break
                case '4':
                    return '星期四'
                    break
                case '5':
                    return '星期五'
                    break
                case '6':
                    return '星期六'
                    break
                case '7':
                    return '星期日'
                    break
                default:
                    return ''
                    break
            }
        },
        startTimeLabel(val){
            switch (val){
                case 0:
                    return '从现在开始'
                    break
                default:
                    return val + '小时后'
                    break
            }
        },
        endTimeLabel(val){
            switch (val){
                case 7:
                    return '7天'
                    break
                case 15:
                    return '15天'
                    break
                default:
                    return val + '个月'
                    break
            }
        },
        intervalLabel(val){
            switch (val){
                case 15:
                    return '15分钟'
                    break
                case 30:
                    return '30分钟'
                    break
                default:
                    return val/60 + '小时'
                    break
            }
        },
    },
    computed:{
        handleStep(){
            switch (this.form.appointment_interval_time){
                case 60:
                    return '01:00'
                    break
                case 120:
                    return '02:00'
                    break
                default:
                    return '00:'+this.form.appointment_interval_time
                    break
            }
        },
        handleEnd(){
             switch (this.form.appointment_interval_time){
                case 15:
                    return '23:45'
                    break
                case 30:
                    return '23:30'
                    break
                case 60:
                    return '23:00'
                    break
                case 120:
                    return '22:00'
                    break
                default:
                    return ''
                    break
            }
        }
    },
  created() {
      this.getSetInfo()
  },
  methods: {
      handleCheckAllChange(val){
           this.form.appointment_date = val ? this.weeks : [];
            this.isIndeterminate = false;
      },
      handleCheckedTimeChange(value) {
        this.checkAll = value.length === this.weeks.length;
        this.isIndeterminate = value.length > 0 && value.length < this.weeks.length;
      },
      addPeriod(){
          this.form.appointment_time_frame.push(['08:00','22:00'])
      },
      delPeriod(index){
          this.form.appointment_time_frame.splice(index,1)
      },
      getSetInfo(){
        this.$axios.post(this.$api.beauty.setinfo).then(res => {
          if (res.code == 0) {
            if(res.result){
                let info = res.result
                info.appointment_date = JSON.parse(info.appointment_date)
                this.checkAll = info.appointment_date.length === this.weeks.length;
                this.isIndeterminate = info.appointment_date.length > 0 && info.appointment_date.length < this.weeks.length;
                info.appointment_time_frame = JSON.parse(info.appointment_time_frame)
                info.appointment_time_frame.forEach(item=>{
                    item[0] = this.handleTime(item[0]*1000)
                    item[1] = this.handleTime(item[1]*1000)
                })
                this.form = info
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      },
        preservation(){
            if(this.form.appointment_date.length==0 || this.form.appointment_start_time==='' ||
                !this.form.appointment_end_time || !this.form.appointment_interval_time || this.form.appointment_time_frame.some(item=>{return !item[0] || !item[1]})){
                    this.$message({
                        message: '请将有*前缀的信息填写完整',
                        type: 'warning'
                    })
                    return
            }
            let one = new Date(new Date().toDateString()).getTime()/1000
            let data = Object.assign({},this.form)
            data.appointment_time_frame.forEach(item=>{
                item[0] = one + item[0].split(':')[0]*60*60 + item[0].split(':')[1]*60
                item[1] = one + item[1].split(':')[0]*60*60 + item[1].split(':')[1]*60
            })
            this.$axios.post(this.$api.beauty.setedit,data)
                .then(res => {
                if (res.code == 0) {
                    if(res.result){
                        this.$message.success('保存成功');
                        this.getSetInfo()
                    }
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
       handleTime(time){
            if (!time) return;
            var date = new Date(time);
            let h = date.getHours();
            let m = date.getMinutes();
            return `${h<10?'0'+h:h}:${m<10?'0'+m:m}`;
        }
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  height: 100%;
}
.row{
    display: flex;
}
.el-icon-close{
    margin-left: 20px;
    font-size: 20px;
    cursor: pointer;
    &:hover{
        color: #449ffc;
    }
}
</style>
